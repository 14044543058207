<template>
  <div class="page-wrapper">
    <WarehouseLocationTable
      :rows="$store.getters.warehouseLocations"
      @delete="deleteLocation"
      @edit="crudMixin_openForm('warehouseLocation', $event)"
      @new-item="crudMixin_openForm('warehouseLocation')"
    />

    <v-dialog
      v-model="isWarehouseLocationFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <WarehouseLocationForm
        :dialog="isWarehouseLocationFormOpen"
        :form-item="warehouseLocationFormItem"
        @create="locationCreated"
        @update="locationUpdated"
        @cancel="isWarehouseLocationFormOpen = false"
      />
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable max-len */
import WarehouseLocationForm from '../components/forms/WarehouseLocationForm';
import WarehouseLocationTable from '../components/tables/WarehouseLocationTable';
import crudMixin from '../mixins/crud-mixin';
import warehouseLocationService from '../api/warehouse-location-service';
import { SET_WAREHOUSE_LOCATIONS } from '../store/mutation-types';
import { removeItemById, updateItemById } from '../util/array';

export default {
  name: 'WarehouseLocations',

  components: {
    WarehouseLocationForm,
    WarehouseLocationTable,
  },

  mixins: [crudMixin],

  data() {
    return {
      warehouseLocationFormItem: {},
      isWarehouseLocationFormOpen: false,
    };
  },

  methods: {
    locationCreated(location) {
      this.$store.commit(SET_WAREHOUSE_LOCATIONS, [
        ...this.$store.getters.warehouseLocations,
        location,
      ]);
    },

    locationUpdated(location) {
      this.$store.commit(
        SET_WAREHOUSE_LOCATIONS,
        updateItemById(this.$store.getters.warehouseLocations, location),
      );
    },

    deleteLocation(location) {
      this.crudMixin_delete(
        warehouseLocationService.delete,
        warehouseLocationService.model,
        location,
      ).then(() => {
        this.$store.commit(
          SET_WAREHOUSE_LOCATIONS,
          removeItemById(this.$store.getters.warehouseLocations, location.id),
        );
      });
    },
  },
};
</script>
